import { LongTextContainer } from 'components/LongText/styles';
import FadeInSection from '../../components/FadeInSection';
import { Grid, GridItem, GridRow } from '../../components/Grid';
import { MetaBallContainer } from '../../components/MetaBallContainer';
import { ModalBox } from '../../components/ModalBox';
import { Text as Paragraph } from '../../components/Typography';
import { ImagesService } from 'service/images/imagesService';

import {
  Content,
  DescriptionText,
  DescriptionTitle,
  Image,
  ImageCopyWrapper,
  ImageWrapper,
  ImageWrapperStyle,
  MainImageCopy,
  MainWrapper,
  Play,
  PlayStyle,
  Video,
  VideoWrapper,
} from './styles';

import type { ImageCopy as TImageCopy } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';

interface IImageCopy extends ISharedTemplateProps {
  templateProps: TImageCopy;
  thumbnailUrls?: string[];
  embedUrl?: string;
  uploadDate?: string;
  duration?: string;
  interactionCount?: number;
  description?: string;
}

export const ImageCopy = ({
  templateProps: { topRightAsset, bottomLeftAsset, imageCopyItem: imageCopyItems },
  thumbnailUrls,
  embedUrl,
  uploadDate,
  duration,
  interactionCount,
  description,
}: IImageCopy) => (
  <ImageCopyWrapper>
    <MetaBallContainer
      data-test="metaball-container"
      topRightImage={topRightAsset?.asset?.[0].url}
      bottomLeftImage={bottomLeftAsset?.asset?.[0].url}
    >
      <div className="sm:py-16 md:py-0">
        <Grid>
          <GridRow columns={16}>
            <GridItem colSpan={10} colStart={4} className="flex flex-wrap justify-between">
              {imageCopyItems?.map((items) => (
                <MainImageCopy columnType={items.columnType ?? ''} key={items.id}>
                  <FadeInSection>
                    <MainWrapper
                      imagePosition={items.imagePosition ?? ''}
                      videoPosition={items.videoPosition ?? ''}
                    >
                      {items?.image && items.image.length !== 0 && items.addImage && (
                        <ImageWrapper
                          imagePosition={items.imagePosition ?? ''}
                          className={ImageWrapperStyle}
                          video={items.addVideo ?? false}
                        >
                          <Image
                            sources={ImagesService.getPictureSources(items?.image?.[0]?.asset?.[0])}
                            alt="Image Copy"
                          />
                        </ImageWrapper>
                      )}
                      {items.addVideo && items.videoId && (
                        <VideoWrapper videoPosition={items.videoPosition ?? ''}>
                          <Video>
                            <Play className={PlayStyle}>
                              <ModalBox
                                data-test="video-modal-box"
                                videoID={items.videoId}
                                description={description || ''}
                                thumbnailUrls={thumbnailUrls ?? []}
                                embedUrl={embedUrl || ''}
                                uploadDate={uploadDate || ''}
                                duration={duration || ''}
                                interactionCount={interactionCount || 0}
                              />
                            </Play>
                            <Image
                              src={items?.videoPosterImage?.asset?.[0]?.url ?? ''}
                              alt={items?.videoPosterImage?.asset?.[0].altText ?? ''}
                            />
                            <Image
                              sources={ImagesService.getPictureSources(
                                items.image?.[0]?.asset?.[0],
                              )}
                              alt="Image Copy"
                            />
                          </Video>
                        </VideoWrapper>
                      )}
                      {(items.addTitle || items.addDescription) && (
                        <Content
                          imagePosition={items.imagePosition ?? ''}
                          image={items.addImage ?? false}
                          video={items.addVideo ?? false}
                          columnType={items.columnType ?? ''}
                        >
                          {items.addTitle && (
                            <DescriptionTitle tag="h4" type="sm" data-test="left-title">
                              {items.title}
                            </DescriptionTitle>
                          )}
                          {items.addDescription && (
                            <DescriptionText data-test="left-description">
                              <LongTextContainer>
                                <Paragraph tag="div" type="md">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: items.description?.html || '',
                                    }}
                                  />
                                </Paragraph>
                              </LongTextContainer>
                            </DescriptionText>
                          )}
                        </Content>
                      )}
                    </MainWrapper>
                  </FadeInSection>
                </MainImageCopy>
              ))}
            </GridItem>
          </GridRow>
        </Grid>
      </div>
    </MetaBallContainer>
  </ImageCopyWrapper>
);
