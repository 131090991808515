import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Title } from '../../components/Typography';
import NextImage from 'components/NextImage';

export const ImageCopyWrapper = tw.div`
px-0
overflow-hidden
items-center
flex
`;

export const ImageWrapper = styled.div<{ imagePosition: string; video: boolean }>`
  ${(p) =>
    p.imagePosition === 'Left' &&
    !p.video &&
    `width: calc(50% - 10px);
    margin-right: 20px;
    `}
  ${(p) =>
    p.imagePosition === 'Right' &&
    !p.video &&
    `width: calc(50% - 10px);
    margin-left: 20px;
    order: 1;`}
  ${(p) =>
    p.imagePosition === 'Right' &&
    p.video &&
    `width: calc(50% - 10px);
    order: 1;`}
  ${(p) => p.imagePosition === 'Left' && p.video && 'width: calc(50% - 10px);'}
  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 0 0 20px;
    order: 0;
  }
`;
export const VideoWrapper = styled.div<{ videoPosition: string }>`
  ${(p) =>
    p.videoPosition === 'Left' &&
    `
    width: calc(50% - 10px);
    margin-right: 20px;`}
  ${(p) =>
    p.videoPosition === 'Right' &&
    `
    width: calc(50% - 10px);
    margin-left: 20px;
    order: 1;`}
  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 0 0 20px;
    order: 0;
  }
`;
export const ImageWrapperStyle = 'align-top relative';
export const Image = tw(NextImage)`
  rounded-lg
  w-full
 `;

export const DescriptionWrapper = tw.div`
  p-2
  w-1/2
  overflow-hidden
`;

export const DescriptionTitle = tw(Title)`
  font-medium
  text-2xl
  md:mb-5
  sm:mb-2.5
`;

export const DescriptionText = styled.div`
  p {
    margin: 0;
  }
`;

export const MainImageCopy = styled.div<{ columnType: string }>`
  display: inline-block;
  padding-bottom: 40px;
  ${(p) => p.columnType === 'Full' && ' width: 100%;'}
  ${(p) =>
    p.columnType === 'Half' &&
    ` width: calc(50% - 10px);
    `}
  @media screen and (max-width: 480px) {
    width: 100%;
    padding-bottom: 20px;
  }
`;
export const Content = styled.div<{
  imagePosition: string;
  image: boolean;
  video: boolean;
  columnType: string;
}>`
  display: inline-block;
  ${(p) =>
    p.image &&
    `width: calc(50% - 10px);
    `}
  ${(p) =>
    p.video &&
    `width: calc(50% - 10px);
    `}
  ${(p) => p.columnType === 'Multi_column' && 'column-count: 2;'}
  @media screen and (max-width: 480px) {
    width: 100%;
    column-count: 1;
  }
`;

export const Play = styled.span`
  left: calc(50% - 40px);
  top: calc(50% - 40px);
`;
export const PlayStyle = 'absolute cursor-pointer sm:w-20 sm:h-20 md:w-20 md:h-20 z-10';
export const Video = tw.div`
  relative
`;

export const MainWrapper = styled.div<{ imagePosition: string; videoPosition: string }>`
  ${(p) =>
    (p.imagePosition === 'Top' || p.videoPosition === 'Top') &&
    `display: flex;
    flex-direction: column;`}
  ${(p) =>
    (p.imagePosition === 'Left' ||
      p.videoPosition === 'Left' ||
      p.imagePosition === 'Right' ||
      p.videoPosition === 'Right') &&
    `display: flex;
    flex-direction: row;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
    `}
`;
